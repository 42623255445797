/*** Container for news content by-line, date and sharing tools ***/
.article-wrapper{
  max-width: unset; 

  .articles, .newsArticle, .next-article{ margin: 0px auto;
    @include general-breakpoint(landscape){
      max-width: $sm-alt-max-content-width;
    }

    @include general-breakpoint(smtablet){
        max-width: $md-alt-max-content-width;
    }

    @include general-breakpoint(smdesktop){
        max-width: $md-max-content-width;
    }

    @include general-breakpoint(desktop){ 
        max-width: $lg-max-content-width;
    }
  }
}

.article-ad-container{
  margin: 30px 0px;
  background: transparent;
}
.article-inner-wrapper {
  position: relative;

  .error-indicator {
    text-align: center;
    padding: 40px;
  }

}
.newsArticle{
  position:relative;
  background:#FFF;
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .article-sticky-ad {
      position:sticky;
      float:right;
      top:0px;
      padding-top:89px;
      padding-bottom:131px;
      margin-right:40px;
      z-index: 1;
    }
    
    .article-sponsor-ad {
      position:relative;
      float:right;
      top:0px;
      padding-top:89px;
      padding-bottom:84px;
      margin-right:40px;
    }

    .article-sticky-social {
      position: sticky;
      max-width: 80px;
      top:0px;
      padding-top:89px;
      padding-bottom:131px;
      z-index: 1;
      .articleInfo { background:transparent;
        .info-row .socialShare {
          display:flex;
          flex-direction: column;
        }
      }
      
    }
  }

  .articleInfo.social-share-div {
    margin-bottom:0px;
    i {font-size: px2rem(20); color:$usoLtBlue;
      &.icon-facebook {color:#4267B2;}
      &.icon-twitter {color:$black;}
    }
  }
  .uso-news {
    &:nth-child(2){
      margin-top:0;
      //margin-left:20%;
    }
    .rich-text {
      .adunitContainer {display:none;}
    }
  }
  .articleAd{
    position:absolute;
    right: 15px;
    z-index: 990;
    @include media-breakpoint-down(xs){
      position: relative;
      right: 0;
      justify-content: center;
      display: flex;
    }
  }  
  .article-ad-container{
    width: 100%;
    position: relative;
    min-height:50px;
    text-align: center;
    font-weight:bold;
    font-size:2em;
    background:$white;
    @include scores-ad-breakpoint(medium) {
        min-height:90px;
        > div {
            //padding:7px 0px;
        }
    }
  }

}
article {
  .uso-media {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 0;
    padding-bottom: 30px;
    background-color: $white;
    @include media-breakpoint-down(xs){
      padding-bottom: 20px;
    }
  }
}

 .articles{
  // max-width:1280px!important;
  border-top: 5px solid #00288C;
}

.articles .heroImage {
  .heroInfo{
    h1{
      font-size:24px;
    }
  }

  .watsonx-branding {
    padding: 10px;

    span.watsonx-logo {
      display: block;
    }
  }
}



/** AI Catch Up Box */
.ai-catch-up-box {

  @include make-col-ready();
  color: $gray_4a;
  margin-bottom: 30px;
  padding: 4px 16px 4px 16px;
  background-color: $ai-catchup-bg;
  position: relative;
  font-size: px2rem(16);


  @include media-breakpoint-up(sm) {
    @include make-col(10);
  }
  @include media-breakpoint-up(md) {
    @include make-col(11);
  }

  &.top-border {
      border-top: 3px solid $ai-catchup-border;
  }

  h3 {
    @include interstate(black);
    font-size: px2rem(14);
    font-weight: bold;
    padding-top: 30px;
    text-align: center;
  }

  h4 {
    @include interstate(black);
    font-size: px2rem(14);
    font-weight: bold;
    padding-top: 30px;
    text-align: center;
    text-transform: uppercase;
  }

  .ai-catch-up-content {
    padding: 20px;
    
      ul {
          padding-left: 16px;
          margin-top: 30px;

          li {
              line-height: 1.2em;
              margin-top: 15px;

              &:first-child {
                  margin-top: 0;
              }
          }
      }
  }

  .watsonx-branding {
    justify-content: center;
    margin-bottom: 20px;
  }
}

@include general-breakpoint(smtablet) {
  .articles .heroImage {
    // height:540px!important;
    background-color:#F0F2F3;
    padding-top:0px;
    @include general-breakpoint(landscape) {
      height: auto;
    }
    @include media-breakpoint-up(xl) {
      height: auto;
    }

    .heroInfo{
      width:40%;
      float:left;
     // background-color: #979797;
      h1{
        font-size:22px;
        padding:20px;
      }
      .articleInfo {
        margin-bottom: 0;

        .watsonx-branding {
          font-size: px2rem(13);
        }

        .watsonx-logo {
          font-size: px2rem(13);
        }
      }
    }
    .heroContainer{
        width:60%;
        position:relative;
        float:right;
        // height:540px;
        .img{
          // height:540px!important;
          width:960px;
          
         
        }
  }
  
    .watsonx-branding {
      font-size: px2rem(14);
      padding: 20px 0px;
    }
  }
  .newsArticle{
    .uso-news{
    // flex-direction:column;
      &:nth-child(2){
        margin-top:-280px;
        //margin-left:20%;
      }
      .articleInfo{
        background-color: #ffffff;
        .info-row{
          .socialShare{
            flex-direction:column-reverse;
          }
        }
      }
      .change-font-size{
        width: 100%;
        margin-left: 18%;
        padding-top:20px;
      }
      .rich-text{
        flex:auto;
        padding-left:40px;

        .adunitContainer {display:block;}
        .articleAd {
          top:-300px;
        }

        &.social {
          display:flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }

    .articleInfo.social-share-div {
      margin-bottom:30px;
    }
  }

  .ai-catch-up-box {
    margin-left: 60px;
    margin-right: 60px;
    font-size: px2rem(18);
    flex: auto;

    h3 {
      font-size: px2rem(18);
    }
  }
}


@include general-breakpoint(tablet) {
  .newsArticle {
    .uso-news {
      .rich-text {
          .adunitContainer {display:block;}
      }
    }
  }
}


@include general-breakpoint(smdesktop) {
  .articles .heroImage {
    .heroInfo{
      h1{
        font-size:36px;
        padding:20px;
      }

      .articleInfo {
    
          .watsonx-branding {
            font-size: px2rem(16);
            padding: 20px 10px;
          }
    
          .watsonx-logo {
            font-size: px2rem(16);
          }
      }
    }
  }
}

@include general-breakpoint(desktop) {
  .articles .heroImage {
    .heroInfo{
      h1{
        font-size:40px;
        padding:20px;
      }
    }
  }
  .newsArticle {
    .uso-news {max-width: calc(1280px - 160px); margin:auto 0px;
      &:nth-child(2){
        margin-top:0;
        //margin-left:20%;
      
      }
      &:nth-child(3){
        margin-top:-505px;
        //margin-left:20%;
      }

      .rich-text {
        &.related {margin-left: 80px; margin-right:80px;}
        //p {padding-right:160px;}
        .adunitContainer { display:none;
          & + p {
            padding-right:0px;
          }
        }
      }
    }
    .uso-media{max-width: calc(1280px - 160px);}
    .premium-section {max-width: calc(1280px - 160px); margin-left:0px; margin-right:0px;}
  }

  .ai-catch-up-box {
    margin-left: 80px;
  }
}


.heroInfo{ 
  background-color:#F0F2F3;
  .articleInfo {
    .info-row .info{
      margin-left:0px;
    }
  }
}
@media only screen and (max-device-width: 767px){
.articles .heroImage span {
  display: flex;
  justify-content: flex-start;
  flex-direction: column-reverse;
}
.heroInfo h1{
  padding:30px 30px 0 30px;
}
.font-increase, .font-decrease{
  // width:25px!important;
  // height:28px;
}
.mobile-Ad{
  display:none;
}
.uso-news{
  display:flex;
  flex-direction:row-reverse;
}
.social-share-div {
  width:auto!important;
  background-color:#ffffff!important;
}
}

.articleInfo {
    margin: auto auto 30px auto;
    @include make-container();
    background-color: $lt_gray;
    .info-row {
      @include make-row();
      max-width: 1280px;
      margin: auto;
      .info {
        @include interstate(light);
        color: $md-gray;
        font-size: 1.2rem;
        display: table;
        height: 80px;
        @include make-col-ready();
  
        @include media-breakpoint-up(xs) {
          @include make-col(8);
        }
        @include media-breakpoint-up(sm) {
          @include make-col-offset(1);
          @include make-col(8);
          height: auto;
          max-height: 70px;
        }
        @include media-breakpoint-up(md) {
          @include make-col-offset(2);
          @include make-col(8);
          max-height: 85px;
        }
        .info-cell {
          display: table-cell;
          vertical-align: middle;
          .author {
            margin-right: 4px;
          }
          .date {
            >div {
              display: inline;
            }
          }
        }
      }
      .socialShare {
        @include make-col-ready();
  
        @include media-breakpoint-up(xs) {
          @include make-col(4);
          button {
            margin-right: 8px;
          }
        }
        @include media-breakpoint-up(sm) {
          @include make-col(2);
          line-height: 70px;
          button {
            width: 20px;
          }
        }
        @include media-breakpoint-up(md) {
          line-height: 85px;
        }
        //display:inline-block; text-align: right;
        display:flex;
        ul{padding-left: 0px; margin-bottom: 0; }
        li{ list-style: none; display: inline; padding-left: 10px; }
        img{ width: 20px; height: 20px; }

        line-height: 80px;
          .app{
            .icon-share{ font-size: px2rem(28); color: $usoDkGray;}
            .icon-share2{ font-size: px2rem(24); color: $usoDkGray;}
        }
      }
      
    }
    @include general-breakpoint(smtablet, max) {
      .webview & {
        &.social-share-div {
          margin-bottom:15px;
        .info-row {
          
            
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .socialShare {
              max-width: unset;
              flex:1;
              width:auto;
              padding-left: 0px;
              padding-right: 7px;
              display: flex;
              justify-content: center;
            }
            // this makes the ad to center.
            &:after {
              flex: 1;
              content: '';
            }
            .adunitContainer {
             // align-content: center;
            }
          }
        }
      }
    }
  }
  /*** Container for page sponsor appearing below the by-line / social section ***/
  .pageSponsor {
    @include general-breakpoint(landscape){
      max-width: $sm-alt-max-content-width;
    }

    @include general-breakpoint(smtablet){
      max-width: $md-alt-max-content-width;
    }

    @include general-breakpoint(smdesktop){
      max-width: $md-max-content-width;
    }

    @include general-breakpoint(desktop){ 
      max-width: $lg-max-content-width;
    }
    margin: 0 auto;
    text-align: center;
    font-size: 1rem;
    @include interstate(light);
    color: $md-gray;
    background-color: $white;
    padding-top: 5px;
    padding-bottom: 0;
    &.noInfo { margin-top: 20px; }
    .sponsorInfo {
      position: relative;
      a {
        padding: 0 60px;
        background-color: $white;
        z-index: 1;
        position: relative;
        img {
          max-width: 150px;
          margin-top: 5px;
        }
      }
      .sponsorLine {
          position: absolute;
          display: block;
          bottom: 45%;
          overflow: hidden;
          width: 100%;
          height: 1px;
          content: '\a0';
          background-color: $dk-gray2;
      }
    }
  }
  /*** Containing .row class for general rich text ***/
  
  .uso-intro {
    width: 100%;
    font-size: 1.6rem;
    @include interstate(regular);
    color: $dk-gray2;
    padding: 20px 0;
    > div {
      width: 100%; max-width: 1280px; margin: auto;
      > div {
        @include make-col-ready();
        @include media-breakpoint-up(xs) {
          @include make-col(12);
        }
        @include media-breakpoint-up(sm) {
          @include make-col(10);
          @include make-col-offset(1);
        }
        text-align: center;
        h1 { font-size: 2.8em; padding: 10px 5px; }
        h2 { font-size: 2.4rem; padding: 10px 5px; }
        h3 { font-size: 2rem; }
        h4 { font-size: 1.6rem; }
        h5 { font-size: 1.2rem; }
        h6 { font-size: 1rem; }
        ol, ul, dl { margin-left: 30px; }
      }
    }
    &.gray-bg { background-color: $lt_gray; margin-top: -10px; }
  }
  
  .uso-content {
    width: 100%;
    max-width: 1280px;
    margin: auto;
    > div {
      font-size: 1.3rem;
      @include make-col-ready();
      max-width: unset;
      // @include media-breakpoint-up(xs) {
      //   @include make-col(12);
      // }
      @include media-breakpoint-up(sm) {
        @include make-col(10);
        @include make-col-offset(1);
      }
      .rich-text { color: $md-gray; }
  
      h1, h2, h3, h4, h5, h6 {
        @include interstate(regular);
        color: $dk-gray2;
      }
      h1 { font-size: 2.8em; padding: 10px 5px; }
      h2 { font-size: 2.4rem; padding: 10px 5px; }
      h3 { font-size: 2rem; }
      h4 { font-size: 1.6rem; }
      h5 { font-size: 1.2rem; }
      h6 { font-size: 1rem; }
      ol, ul, dl { margin-left: 30px; }
    }
    .rich-text { color: $md-gray; }
  
    h1, h2, h3, h4, h5, h6 {
      @include interstate(regular);
      color: $dk-gray2;
    }
    h1 { font-size: 2.8em; padding: 10px 5px; }
    h2 { font-size: 2.4rem; padding: 10px 5px; }
    h3 { font-size: 2rem; }
    h4 { font-size: 1.6rem; }
    h5 { font-size: 1.2rem; }
    h6 { font-size: 1rem; }
    ol, ul, dl { margin-left: 30px; }
  }
  /*** Containing .row class for news rich text ***/
.uso-news, .prContainer, .uso-page-content {
  min-height: 1px; //fix to make visibility sensor work
  background-color: $white;
  @include media-breakpoint-up(sm) {
    @include make-row();
    justify-content: center !important;
  }
  @include media-breakpoint-up(sm) {
    max-width: 1280px;
    margin: auto;
  }
  .change-font-size{
    display:flex;
    margin:20px;
    font-size:14px;
    justify-content: flex-start;
    align-items: center;
  }

  .font-increase, .font-decrease{
    color:#979797;
      margin-left:20px;
      border-radius: 90px;
      width: 29px;
      height: 29px;
      line-height: 24px;
     // height: 100px;
     // content: "+";
      font-size:20px;
      //padding-left:4px;
      border:1px solid #979797;
      display:inline-block;
      text-align: center;
      display:flex;
      flex-direction: row;
      justify-content: center;
      //align-items: center;
      cursor:pointer;
  }
  .font-decrease{
   //padding-left:6px;
  }
  .rich-text {
    @include make-col-ready();
    font-size: 18px;
    // @include media-breakpoint-up(xs) {
    //   @include make-col(10);
    // }
    @include media-breakpoint-up(sm) {
      @include make-col(10);
    }
    @include media-breakpoint-up(md) {
      @include make-col(11);
    }

      .ad-unit, .adunitContainer {
          width: 100%; margin-left: -16px; margin-right: -16px; margin-bottom: 20px; background: transparent;
          width: 300px; height: 250px;
          float: right; margin: 0 0 40px 40px;
        @include general-breakpoint(desktop) {
          width: 160px; height: 600px; float: right; margin: 0 0 40px 40px;
        }
      }
    ol, ul, dl { margin-left: 30px; }
    h1, h2, h3, h4, h5, h6 {
      @include interstate(regular);
    }
    h1 { font-size: 2.8em; padding: 10px 5px; }
    h2 { font-size: 2.4rem; padding: 10px 5px; }
    h3 { font-size: 2rem; }
    h4 { font-size: 1.6rem; }
    h5 { font-size: 1.2rem; }
    h6 { font-size: 1rem; }
    .block-quote {
      margin: 10px 0;
      @include media-breakpoint-up(md) {
        @include make-col-ready;
        @include make-col(12);
        font-size: calc((36/14) * 1rem);
      }
      @include media-breakpoint-up(lg) {
        @include make-col(6);
        font-size: calc((40/14) * 1rem);
      }
      @include media-breakpoint-up(xl) {
        @include make-col(5);
      }
      &.full {
        @include make-col(12);
      }
      @include media-breakpoint-up(lg) {
        &.left { float: left !important; margin-left: 0; padding-left: 0; }
        &.right { float: right !important;  margin-right: 0; padding-right: 0;  }
      }
    }
    &.social {
      text-align: center;
      display:flex;
      justify-content: center;
      // min-height: 550px;
      > div {width:100%; display:flex; flex-direction: column; justify-content: center; align-items: center;}

      iframe, twitter-widget, twitter-tweet, div[twdiv='yes'] { margin-left: auto !important; margin-right: auto !important; }
      
      div[twdiv='yes'] { 
        display: inline-block; 
        width: 100%;
        margin:0px auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      &.small {
        min-height: 300px;
      }
      &.tall {
        min-height: 865px;
      }
    }
    &.related {
      border: 1px solid $usoltMedGray;
      background-color: $usoBkgGray;
      padding: 20px;
      margin-bottom: 30px;
      .related-container {
        display: flex;
        border: 1px solid $usoLtGray;
        .related-photo {
          width: 35%;
          img {
            width: 100%;
          }
        }
        .related-caption {
          width: 65%;
          background-color: $white;
          padding: 2%;
          font-size: px2rem(14); line-height: px2rem(16);
          @include general-breakpoint(smtablet){ padding: 20px; font-size: px2rem(18); line-height: px2rem(27);}
        }
      }

      a {
        color: $bright-blue;
        b, strong { color: $dk-gray2; text-transform: uppercase; }
      }
    }
  }
  .block-quote {
    @include interstate(exlight);
    color: $black;
    margin: 10px;
    font-size: calc((32/14) * 1rem);
    @include media-breakpoint-up(sm) {
      @include make-col-ready();
      @include make-col(10);
    }
    @include media-breakpoint-up(md) {
      @include make-col(8);
      font-size: calc((36/14) * 1rem);
    }
    @include media-breakpoint-up(lg) {
      font-size: calc((40/14) * 1rem);
    }

    &.full {  display: block; }
  }
}
  /*** Containing .row class for non-header photos and videos ***/
  .uso-media {
    @include make-row();
    @include media-breakpoint-up(xs) {
      justify-content: center !important;
    }
    margin-bottom: 30px;
    .media-col {
      @include make-col-ready();
      @include media-breakpoint-up(xs) {
        @include make-col(12);
      }
      @include media-breakpoint-up(sm) {
        @include make-col(10);
      }
      @include media-breakpoint-up(md) {
        @include make-col(8);
      }
      .media-16x9 {
        padding-bottom: 56.25%;
        position: relative;
        margin:auto;
        width: 100%;
        .media-wrapper {
          position: absolute;
          top: 0; bottom: 0; left: 0; right: 0;
          .inlineVideo{
            width:100%;
            float: left;
            text-align: center;
            position: relative;
            margin: 10px 0px 20px 0px;
            padding-bottom:56.25%;
            height:0;
            .inlinePlayer {
              position:absolute;
              top:0;
              left:0;
              width:100%;
              height:100%;
            }
            img {
              width: 100%;
            }
            .ovpPlayer, .stageOverlay {
              position: absolute;
              top:0;
              left:0;
            }
            .stageOverlay {
              display: none;
            }
            .playerPoster {
              img.center { width: 3.75em ! important;}
            }
            .caption {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 30%;
              padding: 10px;
              color: white;
              font-size: 1.75rem;
              text-align: left;
              background-image: linear-gradient(to bottom, #000000, transparent);
            }   
          }
          .youtube-video {
            width: 100%;
            height: 100%;
            iframe {
              width: 100%;
              height: 100%;  
            }
          }
        }
      }
    }
    .photo-16x9,
    &.photo-16x9 {
      padding-bottom: 56.25%;
      position: relative;
      margin:auto;
      width: 100%;
      .inlinePhoto, 
      .photo {
        position: absolute;
        top: 0; bottom: 0; left: 0; right: 0;
        img {
          width: 100%;
          height: 100%;
        }
        .caption {
          display: none;
        }
      }
    }
  }
  /*** when a premium section follows a photo, give it some headroom ***/
  .photo-16x9 + .premium-section {
    margin-top: 10px;
  }
  /*** Hide container for inline-gallery ***/
  // section.uso-inline-gallery {
  //   display: none;
  // }
  /*** Container class for buttons, etc ***/
  .uso-interactive {
    width: 100%;
    text-align: center;
    padding-bottom: 16px;
    display: inline-block;
    margin: auto;
    background-color: $white;
    &.half {
      width: 50%;
    }
  
    .hospButton { min-width: 130px; width: auto; padding: 0 12px; }

    &.button-group {
      background-color: $white;
      justify-content: center;
      gap: 30px;
      .uso-button {
        width: 197px;
        padding: 7px;
        @include media-breakpoint-up(md) {
          width: 220px;
        }
        &.dkblue {
          color: $white !important;
          background-color: $usoDkBlue;
        }
        &::after {
          content: url('/assets/images/misc/btn-down-arrow.png');
          vertical-align: middle;
          margin-left: 3px;
        }
      }
    }
  }
  
  .faq_section + .uso-interactive {
    display: block;
    background-color: $lt_gray;
    margin-top: -16px;
  }
  
  .uso-related-news {
    background-color: $lt_gray;
  }
  
  .next-prev-back-bar, .uso-next-article {
    @include make-row();
    @include media-breakpoint-up(xs) {
      justify-content: center !important;
    }
    height: 90px;
    text-align: right;
    @include media-breakpoint-up(sm) {
      height: 55px;
    }
    @include media-breakpoint-up(md) {
      height: 90px;
    }
    .next-article {
      display: table;
      height: 100%;
      @include make-col-ready();
      font-size: 1.4rem;
      @include media-breakpoint-up(xs) {
        @include make-col(10);
      }
      @include media-breakpoint-up(sm) {
        font-size: 1.8rem;
      }
      @include media-breakpoint-up(md) {
        @include make-col(8);
        font-size: 2.2rem;
      }
      a {
        display: table-cell;
        vertical-align: middle;
        padding-right: 30px;
        background: url('/images/misc/article_next_arrow.png') no-repeat right;
      }
    }
  }

  //Power Index - cms
  $smmobile-player-box-width: 92px;
  $mobile-player-box-width: 127px;
  $tablet-player-box-width: 156px;
  $mobile-layout-width: 284px;
  $tablet-layout-width: 690px;
  $desktop-layout-width: 800px;

  .prContainer{ margin: 20px auto; padding-right: 0px; padding-left: 0px;
    
    .cmsPowerRanking, .cmsPowerRankingLTW{  
      @include general-breakpoint(landscape){
          flex: 0 0 83.33333%;
          max-width: 83.33333%;
      }
      @include general-breakpoint(smtablet){ @include make-col-ready(); }
      
      border: 1px solid $gray-light; width: 100%; border-radius: 15px;
      @include general-breakpoint(smtablet){ max-width: $tablet-layout-width;}
      @include general-breakpoint(smdesktop){ max-width: $desktop-layout-width; }

      .mi-tags, .mi-in-the-media-wrapper, .mi-by-the-numbers-wrapper, .mi-footer, .back-to-all-mi, .blog-hide, .have-your-say, .card-head { display: none;}
      
      // .contents-in-header-title{ background: $white; min-height: unset; border-bottom: 1px solid $gray-light; justify-content: space-between; flex-wrap: wrap; padding: 5px 4%;
      //   @include general-breakpoint(smtablet){ flex-wrap: nowrap;}

      //   h1{ color: $black; text-shadow: none; font-size: px2rem(18); }
      //   .display-cms-link{ display: inline-block; 
      //     a{ color: $bright-blue; font-size: px2rem(11); font-weight: bold; padding-top: 10px; 
      //       @include general-breakpoint(smtablet){ font-size: px2rem(16); padding-top: unset;}
      //     }
      //   }
      // }
      // .mi-section.blog-style {
      //   .mi-pr-ranks-wrapper {
      //     background-color: $white;
          
      //     .show-slamtracker {
      //       .ranking-cell.rank-label {
      //         @include general-breakpoint(smtablet, max) {
      //           margin-left: 10px;
      //           margin-right: 10px;
      //         }
      //       }
      //     }
      //   }
      // }
      // .contents-in-header-byline{display: none;}
      // .match-insights-content{ padding: 10px; margin: 0px auto;
      //   @include general-breakpoint(smtablet){ padding: 10px 20px;}
      //   .mi-in-the-media-wrapper, .mi-by-the-numbers-wrapper, .mi-footer{ display: none;}
      //   .mi-power-ranking-wrapper{ border-bottom: none; padding-bottom: 20px;
      //     .mi-section-header{padding-bottom: 0px; padding-right: 15px;
      //       .mi-last-updated{ margin-top: 0px; margin-right: 0px;}
      //     }
      //     .mi-pr-player-info-wrapper{ width: 100%; 
      //       @include general-breakpoint(smtablet){ max-width: $tablet-layout-width;}
      //       @include general-breakpoint(desktop){ max-width: $desktop-layout-width; }
            
      //       .mi-pr-player-info{ z-index: 10;}
      //       .mi-pr-player-country-name .player-name a{ 
      //         text-decoration: none; cursor: pointer;
      //         &:hover{ color: $bright-blue; }
      //       }
      //       .mi-pr-projection{ width: calc(100% * .40);
      //         @include general-breakpoint(smtablet){ width: calc(#{$tablet-layout-width} * .40);}
      //         @include general-breakpoint(desktop){ width: calc(#{$desktop-layout-width} * .40);}
      //       }
      //       .likelihood-win-btn { width: calc(100% * .40);
      //         @include general-breakpoint(smtablet){width: calc(490px * .40);}
      //         @include general-breakpoint(smdesktop){ width: calc(#{$desktop-layout-width} * .40);}
      //       }
      //       .mi-pr-chart{ left: -20px; 
      //         @include general-breakpoint(smtablet){ left: unset;}
      //       }
      //       .player2 .mi-pr-chart{ 
      //         @include general-breakpoint(desktop){ left: 60px;}
      //       }
      //       .mi-pr-charts{ width: 100%; 
      //         @include general-breakpoint(smtablet){ width: calc(#{$tablet-layout-width} - #{$mobile-player-box-width} * 2 - 50px);}
      //         @include general-breakpoint(desktop){ width: calc(#{$desktop-layout-width} - #{$tablet-player-box-width} * 2 - 50px);}
      //       }
      //       img.player-image, .player1, .player2{ width: $smmobile-player-box-width; height: auto;
      //         @include general-breakpoint(smtablet){ width: $mobile-player-box-width; }
      //         @include general-breakpoint(desktop){ width: $tablet-player-box-width; }

      //         .player1, .player2{ width: $mobile-player-box-width; height: auto;}
      //       }
      //     }
      //     .mi-pr-ranks-wrapper, .mi-pr-ranks{ width: 100%; 
      //       background-color: $white;
      //       @include general-breakpoint(smtablet){ max-width: $tablet-layout-width;}
      //       @include general-breakpoint(desktop){ max-width: $desktop-layout-width; }
      //     }
      //     .mi-pr-ranks .ranking-row {
      //       .atp-rank{
      //         font-size: px2rem(38);
      //         @include general-breakpoint(desktop){ font-size: px2rem(54);}
      //         &.ranking-label{ font-size: px2rem(32);
      //           @include general-breakpoint(desktop){ font-size: px2rem(38);}
      //         }
      //       }
      //     }
      //   }
      //   /** MI desktop style for all breakpoints */
      //   .silo { width: 100%; }
      //   .mi-win-factors, .below-desktop .mi-win-factors {
      //       border: solid 1px $md-gray2;
      //       font-size: px2rem(16);
      //       // height: 100%;
      //       margin-left: 0px;
      //       margin-right: 0px;
      //       margin-bottom: 20px;

      //       @include general-breakpoint(smtablet) {

      //       }

      //       .mi-section-header {
      //           .mi-section-title {
      //               @include watsonBackgroundColor;
      //               // background-color: $usoDkBlue;
      //               border-top-right-radius: 7px 7px;
      //               border-top-left-radius: 7px 7px;
      //               color: $white;
      //               display: block;
      //               line-height: 50px;
      //               text-transform: uppercase;
      //               text-align: center;
      //               width: 100%;
      //           }
      //       }
      //   }
      // }
    }
    // .cmsPowerRanking{
    //   h4.mi-section-title  {
    //     padding-left: 20px;
    //     padding-top: 20px;
    //   }
    //   .player-pr{ display: flex; flex-wrap: wrap; min-height: 100px;
    //     .player-pr-title{ width: 100%; display: flex; flex-wrap: wrap;
    //         @include general-breakpoint(smtablet){ justify-content: space-between; align-items: baseline;} 
    //         .mi-last-updated { margin-top: 10px; margin-right: 10px; width: 100%; text-align: right;}
    //         .contents-in-header-title{ width: 100%;padding: 10px 4%;
    //           h1{ color: $black; text-shadow: none; font-size: px2rem(18);
    //             i.icon-info{ margin-left: 10px; font-size: 50%;}
    //           }
    //         }
    //     }
    //   }
    //   .mi-pr-info-wrapper{
    //     display: flex; width: 100%; flex-wrap: wrap;
    //     @include general-breakpoint(smtablet){justify-content: space-between; flex-wrap: nowrap;}
    //     .mi-pr-player-info-wrapper{ 
    //         width: 50%; display: flex; justify-content: space-evenly;max-width: 100%; flex-grow: 1;
    //         img.player-image, .player1, .player2{ width: 127px; height: auto; }                          
    //         .mi-pr-player-info{ width: 127px;}
    //         .mi-pr-player-container a:hover{ color: $bright-blue;}
    //     }

    //     .mi-section {
    //        @include general-breakpoint(desktop, max) {
    //           padding: 10px;
    //        }
    //     }
  
    //     .mi-pr-ranks-section{
    //       &.mobile{ display: block; width: 90%; margin: 0px auto;
    //           .mi-pr-ranks{ margin-top: 0px; }
    //           .mi-pr-ranks-wrapper{ padding-top: 10px; width: 100%; margin-top: 20px;}
    //       }
    //       &.tablet{ display: none; }
          
    //       @include general-breakpoint(smtablet){ 
    //           &.tablet{ display: block; 
    //             width: calc(#{$tablet-layout-width} - #{$mobile-player-box-width} * 2 - 50px);
    //           }
    //           &.mobile{ display: none; }
    //       }
    //     }
    //     .mi-pr-ranks-wrapper, .mi-pr-ranks{ width: 100%; 
    //       @include general-breakpoint(smtablet){ max-width: $tablet-layout-width;
    //         a{ width: 300px; }  
    //       }
    //       @include general-breakpoint(desktop){ max-width: $desktop-layout-width; }
          
    //     }
    //     .mi-pr-ranks{
    //       width: 100%; 
    //       @include general-breakpoint(smtablet){ position: relative;}
    //       @include general-breakpoint(desktop){ font-size: px2rem(38);}
    //       .ranking-cell{  
    //         &.ranking-label{ font-size: px2rem(24);}
    //       }
    //     }
    //   }

    //   @include general-breakpoint(smdesktopt, max) {
    //     .mi-pr-ranks-wrapper {
    //       margin-top: 0;
    //       background-color: $white;
    //     }

    //     .below-desktop.show-slamtracker {
    //       margin-left: 10px;
    //       margin-right: 10px;
    //     }
    //   }
    // }
    .cmsPowerRanking {

      h4.mi-section-title  {
          padding-left: 20px;
          padding-top: 20px;
      }
      .mi-section.blog-style {
        .mi-pr-ranks-wrapper {
          background-color: $white;
          
          .show-slamtracker {
            .ranking-cell.rank-label {
              @include general-breakpoint(smtablet, max) {
                margin-left: 10px;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }

    .cmsPowerRankingLTW{ 
      padding: 10px 10px 0 10px; 
      border-radius: 15px;

      .mi-section-header.power-index, .mi-pr-player-info-wrapper .mi-pr-player-country-name .mi-player-flag {
        display: none;
      }
      @include general-breakpoint(smtablet, max) {

        .LikelihoodToWinBanner .likelihood-win-btn {
          &.left:after {
            left: 13%;
          }

          &.right:after {
            left: 13%;
          }
        }
      }

      @include general-breakpoint(smtablet) {
        .LikelihoodToWinBanner .likelihood-win-btn {
          font-size: px2rem(20);

          &.left:after {
            left: 13%;
          }

          &.right:after {
            left: 87%;
          }
        }

        .PowerIndexChart {
          margin-top: 60px;

          .probability {
            margin-top: 20px;
          }
          
          .ct-chart {
            width: 100px;
            height: 100px;

            &:before {
              font-size: px2rem(24);
              top: 40px;
              left: 30px;
            }
        }

        }
      } // smtablet

      @include general-breakpoint(smdesktop) {
         max-width: $desktop-layout-width; 


        .LikelihoodToWinBanner .likelihood-win-btn {
          font-size: px2rem(16);

          &.left:after, &.right:after {
            left: 45%; // override - only on article left or right is placed at the center
          }
        }

      }

      @include general-breakpoint(desktop) {
        max-width: $desktop-layout-width; 


       .LikelihoodToWinBanner .likelihood-win-btn {
         font-size: px2rem(16);

         &.left:after {
          left: 30%;
        }

        &.right:after {
          left: 70%;
        }
       }

     }
    }
  }

  // .prContainer {
  //   .cmsPowerRankingLTW{ 
  //     padding: 10px 10px 0 10px; 
  //     border-radius: 15px;

  //     #news-style {
  //       .mi-section-header
  //       .mi-player-flag  { display: none;}


  //     }

  //   }
  // }

  .webview{
    .heroInfo h1{
      padding:15px;
    }
    .newsArticle .uso-news .rich-text .adunitContainer {display:none;}
    .newsArticle .uso-news:nth-child(2) { margin-top:0px;}
    .uso-media {
      margin-right: 0;
      margin-left: 0;
      .media-col {
          padding-left: 0;
          padding-right: 0;
      }
    }
    
    .prContainer{
      .cmsPowerRankingLTW{

          .LikelihoodToWinBanner .likelihood-win-btn {
            font-size: px2rem(20);
  
            &.left:after {
              left: 8%;
            }
  
            &.right:after {
              right: 8%;
            }
          }

        // .match-insights-content{
        //   .mi-tags, 
        //   .mi-in-the-media-wrapper, 
        //   .mi-by-the-numbers-wrapper, 
        //   .mi-footer, 
        //   .back-to-all-mi, 
        //   .blog-hide, 
        //   .have-your-say, 
        //   .card-head, 
        //   .mi-section.factors, 
        //   .mi-section.blog-style,
        //   .mi-section-header.power-index  { display: none;}

          // .mi-pr-player-info-wrapper{ @include general-breakpoint(smtablet){justify-content: space-between; flex-wrap: nowrap;} }
          // .mi-pr-charts{ 
          //   @include general-breakpoint(smtablet){width: calc(#{$tablet-layout-width} - #{$mobile-player-box-width} * 2 - 50px)!important;}
          // }
          // .player2 .mi-pr-chart{ 
          //   @include general-breakpoint(smtablet){ left: 60px;}
          // }
          // .likelihood-win-btn { 
          //   @include general-breakpoint(smtablet){width: calc(490px * .40)!important;}
          //   .btn{ width: 100%!important; 
          //     @include general-breakpoint(smdesktop, max) {height: auto;}
          //   }
          // }
        // }
      }
    }
  }